import {SubscriptionsService} from "../../services/subscriptions.service";

const SUBSCRIPTION_GET_DATA = async ({commit, state}, payload) => {
  let params = {
    take: 50,
    skip: 0,
  }

  const data = await SubscriptionsService.getSubscriptions(params);
  commit('SUBSCRIPTION_SET', ['list', data.body.subscriptions])
}

export default {
  SUBSCRIPTION_GET_DATA
}
