const VENDORS_SET = (state, [variable, value]) => {
  state[variable] = value
}
const VENDORS_PAGINATE = (state, payload) => {
  state.pagination.currentPage = payload;
}
const VENDORS_SEARCH = (state, payload) => {
  state.search = payload;
}
const VENDORS_SORT_CHANGE = (state, payload) => {
  state.column = payload.sortBy;
  state.order = payload.sortDesc ? 'desc' : 'asc';
}

const VENDORS_OPEN_MODAL = (state, payload) => {
  state.vendorModal.show = true;
  if (payload) {
    state.vendorModal.form = {
      ...payload,
      image: null,
      url: payload.image

    }
  }
}

const VENDORS_HIDE_MODAL = (state, payload) => {
  state.vendorModal.show = false;
  state.vendorModal.form = {
    id: null,
    image: null,
    name: null,
    subscription_id: null,
  }
}


const VENDORS_USERS_OPEN_MODAL = (state, payload) => {
  state.userModal.show = true;
  if (payload) {
    state.userModal.form = {
      ...payload,
    }
  }
}

const VENDORS_USERS_HIDE_MODAL = (state, payload) => {
  state.userModal.show = false;
  state.userModal.form = {
    id: null,
    vendor_id:null,
    name: null,
    surname: null,
    phone: null,
    password: null,
    confirm_password: null,
    short_description: null,
    is_active: false
  }
}


export default {
  VENDORS_SET,
  VENDORS_PAGINATE,
  VENDORS_SORT_CHANGE,
  VENDORS_SEARCH,
  VENDORS_OPEN_MODAL,
  VENDORS_HIDE_MODAL,
  VENDORS_USERS_OPEN_MODAL,
  VENDORS_USERS_HIDE_MODAL
}
