import Vue from 'vue'
import {AppSettings} from "../appSettings";
import {UserHelper} from "../helpers/user.helper";


export class OffersService {

  static getOfferCategories() {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/FetchCategories', {});
  }

  static addOffer(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/Add', params);
  }

  static editOffer(params) {
    return Vue.http.put(AppSettings.getUrl() + '/api/Offer/Edit', params);
  }

  static getOffers(params) {
    if (UserHelper.isAdmin()) {
      return Vue.http.post(AppSettings.getUrl() + '/api/Offer/Admin', params);
    } else if (UserHelper.isVendor()) {
      return Vue.http.post(AppSettings.getUrl() + '/api/Offer/FetchAll', params);
    }
  }

  static approveDraft(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/ChangeDraftStatus', params);
  }

  static RevertToDraft(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/RevertToDraft', params);
  }

  static activeToPending(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/ActiveToPending', params);
  }

  static rejectPending(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/RejectPending', params);
  }

  static approvePending(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/ApprovePending', params);
  }

  static ActiveToDraft(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/ActiveToDraft', params);
  }

  static getOfferCodes(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/Codes', params);
  }

  static checkCode(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/CheckCode', params);
  }

  static activateCode(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/Activate', params);
  }

  static addVoucherCodes(params){
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/AddCodes', params);
  }

  static getVoucherAvailableCodes(params){
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/UnsharedCodes', params);
  }

  static removeVoucherCodes(params){
    return Vue.http.post(AppSettings.getUrl() + '/api/Offer/RemoveCodes', params);
  }


}
