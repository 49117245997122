import {OffersService} from "../../services/offers.service";
import {VendorsService} from "../../services/vendors.service";

const GET_PENDING_TOTAL = async ({commit, state}, payload) => {
  let params = {}

  const data = await VendorsService.getPendingTotals(params);

  commit('ADMIN_SET', ['pendingOffers', data.body.offers_total])
  commit('ADMIN_SET', ['pendingVendors', data.body.vendors_total])
}

export default {
  GET_PENDING_TOTAL
}
