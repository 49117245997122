const DISPLAY_CONFIRM_MODAL = (state, payload) => {
  state.confirm = payload;
}

const HIDE_CONFIRM_MODAL = (state, payload) => {
  state.confirm = {
    show: false,
    title: '',
    text: '',
    noText: '',
    yesText: '',
    onConfirm: () => {
    },
    onDecline: () => {
    }
  }
}

const SHOW_VERIFY_VENDOR_MODAL = (state, payload) => {
  state.verifyVendor.show = true
}

const HIDE_VERIFY_VENDOR_MODAL = (state, payload) => {
  state.verifyVendor = {
    show: false,
    subscription_id: null,
    vendor_id:null
  }
}

export default {
  DISPLAY_CONFIRM_MODAL,
  HIDE_CONFIRM_MODAL,
  SHOW_VERIFY_VENDOR_MODAL,
  HIDE_VERIFY_VENDOR_MODAL
}
