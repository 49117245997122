export const state = {
  data: [],
  users: [],
  pagination: {
    total: 0,
    currentPage: 1,
    perPage: 10,
    column: 'id',
    order: 'desc',
    search: null,
    busy: false
  },
  userModal: {
    show: false,
    disabled:false,
    form: {
      id: null,
      vendor_id :null,
      name: null,
      surname: null,
      phone: null,
      password: null,
      confirm_password: null,
      short_description: null,
      is_active: false
    }
  },
  vendorModal: {
    show: false,
    form: {
      id: null,
      image: null,
      name: null,
      description: null,
      subscription_id: null,
      url: null
    }
  }
}
