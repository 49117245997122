import Vue from 'vue';
import Vuex from 'vuex';
import vendors from "./vendors";
import vouchers from "./vouchers";
import subscriptions from "./subscriptions";
import modals from "./modals";
import admin from "./admin";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    vendors,
    vouchers,
    subscriptions,
    modals,
    admin
  },
})
