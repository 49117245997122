export const state = {
  data: [],
  users:[],
  expiredData:[],
  pagination: {
    total: 0,
    currentPage: 1,
    perPage: 10,
    column: 'id',
    order: 'desc',
    search: null,
  },
  filters:{
    start_date:null,
    end_date:null,
    statuses:[],
    search:null
  },
  addVoucherCodeModal:{
    show:false,
    form:{
      offer_id:null,
      code_list:[],
      quantity:null
    }
  },
  removeVoucherCodeModal:{
    show:false,
    offer_id:null
  },
  voucherModal: {
    show: false,
    code_options:[],
    disabled:false,
    disable_codes: false,
    form: {
      id: null,
      offer_name: null,
      status:null,
      price: null,
      quantity: null,
      per_user:null,
      start_date:null,
      end_date:null,
      short_description:null,
      codes:[],
      description:null,
      category_ids:[],
      image:null,
      url:null,
      vendor_id:null,
      as_active:false,
      coming_soon:false,
      buy_period:null,
      code_valid:null
    }
  }
}
