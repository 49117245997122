export class UserHelper {

  static getRole() {
    let data = JSON.parse(localStorage.getItem('user'));
    return data.user.role;
  }

  static isAdmin() {
    let data = JSON.parse(localStorage.getItem('user'));
    return data.user.role === this.AdminRole();
  }
  static isRetail() {
    let data = JSON.parse(localStorage.getItem('user'));
    return data.user.role === this.RetailRole();
  }
  static isVendor() {
    let data = JSON.parse(localStorage.getItem('user'));
    return data.user.role === this.VendorRole();
  }

  static AdminRole(){
    return 'Admin'
  }

  static RetailRole(){
    return 'Retail'
  }

  static VendorRole(){
    return 'Vendor'
  }

}
