const VOUCHERS_SET = (state, [variable, value]) => {
  state[variable] = value
}
const VOUCHERS_FILTER_CHANGE = (state, payload) => {
  state.filters = {
    ...state.filters,
    ...payload
  }
}

const VOUCHERS_RESET_FILTER = (state, payload) => {
  state.filters = {
    start_date: null,
    end_date: null,
    statuses: [],
    search: null
  }
}


const VOUCHERS_SORT_CHANGE = (state, payload) => {
  state.column = payload.sortBy;
  state.order = payload.sortDesc ? 'desc' : 'asc';
}

const VOUCHER_SET_PAGE = (state, payload) => {
  state.pagination.currentPage = payload;
}

const VOUCHERS_CODE_SHOW_MODAL = (state, payload) => {
  state.addVoucherCodeModal = {
    show: true,
    form: {
      offer_id:payload,
      code_list: [],
      quantity: null
    }
  }
}

const VOUCHERS_CODE_HIDE_MODAL = (state, payload) => {
  state.addVoucherCodeModal.show = false
}

const VOUCHERS_CODE_REMOVE_SHOW_MODAL = (state, payload) =>{
  state.removeVoucherCodeModal = {
    show:true,
    offer_id: payload
  }
}

const VOUCHERS_CODE_REMOVE_HIDE_MODAL = (state, payload) =>{
  state.removeVoucherCodeModal.show = false
}

const VOUCHERS_SHOW_MODAL = (state, payload) => {
  state.voucherModal.show = true;
  if (payload) {
    let codeList = payload.codes ? payload.codes.map((elem) => {
      let obj = {};
      obj['code'] = elem;
      obj['name'] = elem;
      return obj;
    }) : [];

    if (payload.id) {
      state.voucherModal.disabled = (payload.status === 'A' )
      state.voucherModal.disable_codes = !!(payload.status === 'A' || (payload.status === 'D' && payload.approved_at))
    }

    state.voucherModal.form = {
      ...payload,
      image: null,
      url: payload.image,
      category_ids: payload.categories,
      codes: [...codeList],
      as_active: !(payload.status === 'D' || (payload.status === 'P' && !payload.approved_at))
    }
    state.voucherModal.code_options = [...codeList];
  }
}

const VOUCHERS_SET_CODE_LIST = (state, payload) => {
  if (payload) {
    let codeList = payload ? payload.map((elem) => {
      let obj = {};
      obj['code'] = elem;
      obj['name'] = elem;
      return obj;
    }) : [];
    state.voucherModal.form = {
      ...state.voucherModal.form,
      codes: [...codeList],
    }
    state.voucherModal.code_options = [...codeList];

  }
}


const VOUCHERS_HIDE_MODAL = (state, payload) => {
  state.voucherModal.show = false;
  state.voucherModal.disabled = false;
  state.voucherModal.code_options = [];
  state.voucherModal.form = {
    id: null,
    offer_name: null,
    price: null,
    quantity: null,
    quantity_per_user: null,
    start_date: null,
    end_date: null,
    short_description: null,
    description: null,
    category_ids: [],
    codes: [],
    image: null,
    url: null,
    vendor_id: null,
    as_active: false,
    coming_soon:false,
    buy_period:null,
    code_valid:null
  }
}

export default {
  VOUCHERS_SET,
  VOUCHERS_SHOW_MODAL,
  VOUCHERS_HIDE_MODAL,
  VOUCHERS_SORT_CHANGE,
  VOUCHER_SET_PAGE,
  VOUCHERS_SET_CODE_LIST,
  VOUCHERS_FILTER_CHANGE,
  VOUCHERS_RESET_FILTER,
  VOUCHERS_CODE_SHOW_MODAL,
  VOUCHERS_CODE_HIDE_MODAL,
  VOUCHERS_CODE_REMOVE_SHOW_MODAL,
  VOUCHERS_CODE_REMOVE_HIDE_MODAL

}
