import Vue from 'vue'
import {AppSettings} from "../appSettings";

export class SubscriptionsService{

  static getSubscriptions(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Subscription/Fetch', params);
  }

}
