export const state = {
  confirm: {
    show:false,
    title:'',
    text:'',
    noText:'',
    yesText:'',
    onConfirm:() =>{},
    onDecline:() =>{}
  },
  verifyVendor:{
    show:false,
    subscription_id:null,
    vendor_id: null
  }
}
