import {VendorsService} from "../../services/vendors.service";
import {UserHelper} from "../../helpers/user.helper";

const VENDORS_GET_DATA = async ({commit, state, dispatch}, payload) => {
  let params = {
    take: state.pagination.perPage,
    skip: (state.pagination.currentPage - 1) * state.pagination.perPage,
  }

  let pagination = state.pagination;
  pagination.busy = true;
  commit('VENDORS_SET', ['pagination', pagination])

  const data = await VendorsService.getVendors(params);

  if(UserHelper.isAdmin()){
    dispatch('admin/GET_PENDING_TOTAL', {}, {root:true})
  }

  pagination = {
    ...pagination,
    total: data.body.total,
    busy: false
  }


  commit('VENDORS_SET', ['data', data.body.vendors])
  commit('VENDORS_SET', ['pagination', pagination])
}


const VENDORS_GET_USERS = async ({commit, state}, payload) => {

}
export default {
  VENDORS_GET_DATA,
  VENDORS_GET_USERS
}
