import {OffersService} from "../../services/offers.service";
import {UserHelper} from "../../helpers/user.helper";

const VOUCHERS_GET_EXPIRED_OFFERS_DATA = async ({commit, state}, payload) => {
  let params = {
    take: state.pagination.perPage,
    skip: (state.pagination.currentPage - 1) * state.pagination.perPage,
    expired: true
  }
  let pagination = state.pagination;
  pagination.busy = true;
  commit('VOUCHERS_SET', ['pagination', pagination])
  const data = await OffersService.getOffers(params);
  pagination = {
    ...pagination,
    total: data.body.total,
    busy: false
  }
  commit('VOUCHERS_SET', ['data', data.body.offers])
  commit('VOUCHERS_SET', ['pagination', pagination])
}

const VOUCHERS_GET_DATA = async ({commit, state, dispatch}, payload) => {
  let params = {
    take: state.pagination.perPage,
    skip: (state.pagination.currentPage - 1) * state.pagination.perPage,
    filter:{
      status: state.filters.statuses ? state.filters.statuses : null,
      search: state.filters.search ? state.filters.search : null
    }
  }
  let pagination = state.pagination;
  pagination.busy = true;
  commit('VOUCHERS_SET', ['pagination', pagination])
  const data = await OffersService.getOffers(params);
  if (UserHelper.isAdmin()) {
    dispatch('admin/GET_PENDING_TOTAL', {}, {root: true})
  }

  pagination = {
    ...pagination,
    total: data.body.total,
    busy: false
  }
  commit('VOUCHERS_SET', ['data', data.body.offers])
  commit('VOUCHERS_SET', ['pagination', pagination])
}

const VOUCHERS_GET_VOUCHER_CODES = async ({commit, state}, payload) => {
  let params = {
    offer_id: payload,
  }

  const data = await OffersService.getOfferCodes(params);

  if (data.body.operation_result.result_code === 200) {
    commit('VOUCHERS_SET_CODE_LIST', data.body.codes)
  }
}


export default {
  VOUCHERS_GET_EXPIRED_OFFERS_DATA,
  VOUCHERS_GET_DATA,
  VOUCHERS_GET_VOUCHER_CODES
}
