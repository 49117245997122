// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es/promise'
import 'core-js/es/string'
import 'core-js/es/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import {Vuelidate} from "vuelidate";
import VueResource from 'vue-resource'
import Toasted from 'vue-toasted';
import store from "./store";
import './filters'
import Multiselect from "vue-multiselect";

// todo
// cssVars()

Vue.component('multiselect', Multiselect)
Vue.use(VueResource)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000
})

Vue.http.interceptors.push((request, next) => {
  if (localStorage.getItem('user')) {
    let user = JSON.parse(localStorage.getItem('user'));
    request.headers.set('Authorization', 'Bearer ' + user.token)
  }
  request.headers.set('Accept', 'application/json')

  next(function (response) {
    if (response.status === 401) {
      if(localStorage.getItem('user')){
        localStorage.removeItem('user')
      }
      router.push({path: '/auth/login'})
      Vue.toasted.error('Unauthorized')
    } else if (response.status === 403) {
      if(localStorage.getItem('user')){
        localStorage.removeItem('user')
      }
      router.push({path: '/auth/login'})
      Vue.toasted.error('Forbidden')
    }
  });
})


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
