import router from "./index";
import {UserHelper} from "../helpers/user.helper";

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Vendors = () => import('@/views/admin/Vendors')
const Vouchers = () => import('@/views/Vouchers')
const Archive = () => import('@/views/Archive')
const Check = () => import('@/views/Check')
const VendorUsers = () => import('@/views/VendorUsers');
// Views - Pages
const Page404 = () => import('@/views/auth/Page404')
const Login = () => import('@/views/auth/Login')
const Register = () => import('@/views/auth/Register')

// Users


const routes = [
  {
    path: '/',
    redirect: '/vouchers',
    name: 'Home',
    component: DefaultContainer,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('user')) {
        router.push({path: '/auth/login'})
      } else {
        next();
      }
    },
    children: [
      {
        path: 'vouchers',
        name: 'Vouchers',
        component: Vouchers,
        beforeEnter: (to, from, next) => {
          if (!UserHelper.isRetail()) {
            next();
          } else {
            localStorage.removeItem('user');
            router.push({path: '/auth/login'})
          }
        }
      },
      {
        path: 'archive',
        name: 'Archive',
        component: Archive,
        beforeEnter: (to, from, next) => {
          if (!UserHelper.isRetail()) {
            next();
          } else {
            localStorage.removeItem('user');
            router.push({path: '/auth/login'})
          }
        }
      },
      {
        path: '/vendors',
        name: 'Vendors',
        component: Vendors,
        beforeEnter: (to, from, next) => {
          if (UserHelper.isAdmin()) {
            next();
          } else {
            localStorage.removeItem('user');
            router.push({path: '/auth/login'})
          }
        }
      },
      {
        path: '/check',
        name: 'Check',
        component: Check,
        beforeEnter: (to, from, next) => {
          if (!UserHelper.isVendor()) {
            next();
          } else {
            localStorage.removeItem('user');
            router.push({path: '/auth/login'})
          }
        }
      },
      {
        path: '/vendor-users',
        name: 'VendorUsers',
        component: VendorUsers,
        beforeEnter: (to, from, next) => {
          if (!UserHelper.isRetail()) {
            next();
          } else {
            localStorage.removeItem('user');
            router.push({path: '/auth/login'})
          }
        }
      },
    ]
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'Pages',
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('user')) {
        router.push({path: '/vouchers'})
      } else {
        next();
      }
    },
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: Page404
  }
]

export default routes;
