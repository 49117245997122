import Vue from 'vue'
import {AppSettings} from "../appSettings";

export class VendorsService {

  static getVendors(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Vendor/All', params);
  }

  static approveVendor(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Vendor/Approve', params);
  }

  static declineVendor(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Vendor/Decline', params);
  }

  static getActiveVendors(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Vendor/Fetch', params);
  }

  static getPendingTotals(params) {
    return Vue.http.get(AppSettings.getUrl() + '/api/Vendor/Totals', params);
  }

  static getVendorUsers(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Vendor/Users', params);
  }

  static changeVendorUserActiveStatus(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Vendor/ChangeActiveStatus', params);
  }

  static createVendorUser(params) {
    return Vue.http.post(AppSettings.getUrl() + '/api/Vendor/RegisterUser', params);
  }

  static updateVendorUser(params) {
    return Vue.http.put(AppSettings.getUrl() + '/api/Vendor/UpdateInfo', params);
  }

}
